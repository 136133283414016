<template>
  <div>
          <v-card :elevation="0">
            <v-stepper v-model="currentStep" alt-labels flat elevation="0" tile vertical>
              <v-stepper-header>
                <v-stepper-step :complete="currentStep > 1" step="1"  >
                  Login
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="currentStep > 2" step="2"  >
                  Permissions
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="currentStep > 3" step="3">
                  Setup
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="currentStep > 4" step="4">
                  Leaderboard
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="currentStep > 5" step="5">
                  Finalize
                </v-stepper-step>

              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content step="1">
                  <h3>
                    Get Started
                  </h3>
                  <p>
                    Add a CorporateFitness.app powered challenge to a chat or channel. All members of the team or chat you add this tab to will be invited to join your challenge once it's published.
                  </p>
                  <p>
                    <v-btn text href="https://corporatefitness.app/features" target="_blank">Features <v-icon x-small class="ml-1">fa-external-link</v-icon></v-btn>
                    <v-btn text href="https://corporatefitness.app/pricing" target="_blank">Pricing <v-icon x-small class="ml-1">fa-external-link</v-icon></v-btn>
                    <v-btn text href="https://corporatefitness.app/contact" target="_blank">Contact Us <v-icon x-small class="ml-1">fa-external-link</v-icon></v-btn>
                  </p>
                  <p>
                    After you click <strong>Login</strong> you can create an account if you don't have one yet.
                  </p>

                  <v-spacer/>
                  <v-btn color="primary" @click="login">
                    Login
                  </v-btn>
                </v-stepper-content>

                <v-stepper-content step="2">
                  <h3>Verify Permissions</h3>
                  <p>
                    Before we continue we need to verify we have the permissions in place to access the selected chat or channel.
                  </p>
                  <v-alert v-if="verifyResponse && verifyResponse.status === 'ERROR'" type="danger">
                    <p>{{verifyResponse.msg}}</p>
                    <p>It looks like special permissions are required before we can continue. Please contact the Microsoft Teams admin in your organization to add our app to Microsoft Teams.</p>
                  </v-alert>

                  <v-btn text @click="currentStep--">
                    Back
                  </v-btn>

                  <v-btn color="primary" @click="verifyPermissions" :loading="$store.getters.isLoading">
                    Verify
                  </v-btn>
                </v-stepper-content>

                <v-stepper-content step="3">
                  <div v-if="!displayMode">
                    <h3 class="mb-2">What is your role?</h3>
                    <BigButton title="Participant" text="Display a (team) result" elevated @click="displayMode='RESULT';prepareMyResults()"/>
                    <BigButton title="Organizer" text="Display an event and leaderboards" elevated @click="displayMode='EVENT';"/>
                  </div>
                  <div v-if="displayMode === 'RESULT'">
                    <h3>Select Your Result</h3>
                    <p>Please note that this list shows results only after the leaderboard has started and you have at least one qualifying activity.</p>
                    <v-progress-circular v-if="$store.getters.isLoading && !myResults" indeterminate :size="100" :width="5" color="primary" class="mx-a"></v-progress-circular>
                    <v-radio-group v-model="selectedResult">
                      <v-radio v-for="(item, idx) in myResults" :key="idx" :label="`${item.event.name}: ${item.race.name} - ${item.result.name}`" :value="item" />
                    </v-radio-group>
                    <v-alert v-if="myResults && myResults.length == 0" type="warning">No results available right now. Please make sure you have at least one qualifying activity in your profile.</v-alert>

                  </div>
                  <div v-if="displayMode === 'EVENT'">
                    <h3>Event Setup</h3>
                    <v-row v-if="!eventMode">
                      <v-col cols="6">
                        <v-card @click="prepareNewEvent" class="pa-4 mt-4 grey lighten-4">
                          <h3>Create New Event</h3>
                          <p class="mb-0">Create a new challenge.</p>
                        </v-card>
                      </v-col>
                      <v-col cols="6">
                        <v-card @click="prepareLinkEvent" class="pa-4 mt-4 grey lighten-4">
                          <h3>Link Existing Event</h3>
                          <p class="mb-0">Use this if you already prepared your event.</p>
                        </v-card>
                      </v-col>
                    </v-row>
                    <div v-else-if="eventMode == 'CREATE'">
                      <v-text-field 
                        v-model="name" 
                        ref="name"
                        :rules="nameRules" 
                        label="How would you like to name your event? (required)" 
                        prepend-icon="fa-pencil-alt"
                        class="mb-4"
                        required>
                      </v-text-field>
                      <v-row>
                        <v-col cols="12" md="6">
                          <DateWithTimeZonePicker v-model="activeFrom" :timeZone="timezone" label="First date (included)" class="mb-4"/>
                        </v-col>
                        <v-col cols="12" md="6">
                          <DateWithTimeZonePicker v-model="activeTill" :timeZone="timezone" label="Last date (included)" class="mb-4"/>
                        </v-col>
                      </v-row>
                    </div>
                    <div v-else-if="eventMode == 'LINK'">
                      <v-progress-circular v-if="!events" indeterminate :size="100" :width="5" color="primary" class="mx-a"></v-progress-circular>
                      <div v-else>
                        <p class="mb-0">Select the event you want to link with your Microsoft Teams channel or chat. This list will show all events you have access to.</p>
                        <p v-if="events.length == 0" class="mb-0">
                          You haven't created an event yet.
                          <v-btn color="primary" @click="eventMode = 'CREATE'">Create New Event</v-btn>
                        </p>
                        <v-list>
                          <v-list-item v-for="(item, idx) in events" :key="idx" @click="linkEvent(item.id)">
                            <v-list-item-content>
                              <v-list-item-title>{{ item.name }}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-icon>fa-chevron-right</v-icon>
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>

                        <!-- <v-radio-group v-model="selectedEventId">
                          <v-radio v-for="(item, idx) in events" :key="idx" :label="item.name" :value="item.id"/>
                        </v-radio-group> -->
                        <p v-if="events.length > 0">
                          <v-btn outlined color="primary" @click="eventMode = 'CREATE'">Create New Event instead</v-btn>
                        </p>
                      </div>
                    </div>
                  </div>
                  <v-divider class="my-8" />
                  <div>
                    <v-btn text @click="currentStep--;displayMode=null;eventMode=null;">
                      Back
                    </v-btn>

                    <v-btn color="primary" @click="createOrLinkEvent" :disabled="(displayMode === 'EVENT' && !name && !selectedEventId) || (displayMode === 'RESULT' && !selectedResult)" :loading="$store.getters.isLoading">
                      Continue
                    </v-btn>
                  </div>
                </v-stepper-content>

                <v-stepper-content step="4">
                  <h3>Add a Leaderboard</h3>

                  <div v-if="selectedResult">
                    <p>You may skip this step as you are linking a result.</p>
                  </div>
                  <div v-else>
                    <v-row class="mb-4" v-if="event">
                      <v-col
                        v-for="(item, idx) in event.races"
                        :key="item.id"
                        cols="12"
                        sm="6"
                        md="4"
                        lg="3"
                      >
                        <RaceCard :event="event" :item="item" :idx="idx" :color="item.color || $helpers.getRaceColor(event, idx)" @click="$refs.raceDialog.editRace(item)" />
                      </v-col>
                    </v-row>
                    
                    <RaceDialog ref="raceDialog" :event="event" @save="saveEvent" :simple="true"/>

                    <p>
                      <v-btn color="primary" outlined class="mr-4" @click="$refs.raceDialog.addDistanceLeaderboard()">
                        <v-icon small class="mr-2">fa-ruler</v-icon> Add Distance Challenge
                      </v-btn>
                      <v-btn color="primary" outlined class="mr-4" @click="$refs.raceDialog.addDurationLeaderboard()">
                        <v-icon small class="mr-2">fa-hourglass-half</v-icon> Add Time Challenge
                      </v-btn>
                      <v-btn color="primary" outlined class="mr-4" @click="$refs.raceDialog.addStepLeaderboard()">
                        <v-icon small class="mr-2">fa-shoe-prints</v-icon> Add Step Challenge
                      </v-btn>
                    </p>
                    <p class="text-muted">You can make more advanced leaderboards configurations after creating the tab.</p>
                  </div>

                  <v-divider class="my-8" />

                  <v-btn text @click="currentStep--">
                    Back
                  </v-btn>

                  <v-btn color="primary" @click="finalize" :disabled="!event || !event.races || event.races.length==0">
                    Continue
                  </v-btn>
                  <v-btn v-if="!event || !event.races || event.races.length==0" color="primary" outlined class="ml-4" @click="finalize">
                    Skip Step
                  </v-btn>
                </v-stepper-content>

                <v-stepper-content step="5">
                  <h3>Finalize</h3>
                  
                  <p>
                    All good! After you click <strong>Save</strong> we will connect this challenge with the selected chat or channel.
                  </p>

                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>

          </v-card>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { EventBus } from '@/plugins/eventbus.js';
import { DateTime } from 'luxon'
import MicrosoftTeamsWrapper from "@/util/microsoftTeamsWrapper";
import accountService from "@/services/accountService";
import profileService from "@/services/profileService";
import eventManagerService from "@/services/eventManagerService";
import DateWithTimeZonePicker from "@/components/DateWithTimeZonePicker";
import DistanceTextArea from "@/components/DistanceTextArea";
import BigButton from "@/components/BigButton";
import RaceDialog from '@/views/eventmanager/_RaceDialog.vue'
import RaceCard from '@/components/RaceCard.vue'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

import i18n from '@/i18n'
// https://docs.microsoft.com/en-us/javascript/api/overview/msteams-client?view=msteams-client-js-latest
//import * as microsoftTeams from "@microsoft/teams-js";
//const microsoftTeams = window.microsoftTeams;

export default {
  components: {
    DateWithTimeZonePicker,
    DistanceTextArea,
    RaceDialog,
    RaceCard,
    BigButton,
  },
  props: {
    //microsoftTeams: null, // This is of type Module, which doesn't match Object
    //teamsContext: Object,
    teamsWrapper: Object,
  },
  data: () => ({
    currentStep: 1,
    verifyResponse: null,
    error: null,
    displayMode: null,
    eventMode: null,
    event: null,
    events: null,
    selectedResult: null,
    selectedEventId: null,
    myResults: null,
    skipLeaderboardStep: false,
    name: null,
    timezone: null,
    activeFrom: null,
    activeTill: null,
    nameRules: [
      v => !!v || "Please enter the event name",
    ],
  }),

  async mounted() {
    this.timezone = DateTime.now().zoneName;
    this.activeFrom = DateTime.now().toISO();
    this.activeTill = DateTime.now().plus({days:7}).toISO();
    await this.loadUserData();
    EventBus.$on('login-state-change', async user => {
      await this.loadUserData();
    });
    this.$store.commit('setContext', 'microsoft-teams');
  },

  methods: {
    async loadUserData() {
      console.log('AUTH STATE changed', this.user);
      //this.tryPrepare();

      if (this.currentStep == 1 && this.user)  {
        this.currentStep = 2;
      }
    },

    async verifyPermissions() {
      if (this.user) {
        const response = await eventManagerService.integrationsTeamsVerify(this.teamsWrapper.getTeamsInfo());
        console.log('Teams permissions result', response.data);
        this.verifyResponse = response.data;
        if (response.data.status == 'OK') {
          this.currentStep ++;
        }
      }
    },

    async login() {
        console.log('login?', this.teamsWrapper);
      if (this.teamsWrapper) {
        if (!this.user) {
          await this.teamsWrapper.login('v2');
        }
        if (this.user) {
          this.currentStep ++;
          //const verified = await this.tryVerifyPermissions();
          //if (verified) {
          //  this.currentStep ++;
          //}
        }
      }
    },

    async prepareNewEvent() {
      this.eventMode = 'CREATE';
      this.$nextTick(() => {
        this.$refs.name.focus();
      });
    },
    async prepareLinkEvent() {
      if (this.user) {
        this.eventMode = 'LINK';
        this.events = (await eventManagerService.getOwnedEvents()).data.data;
      }
    },
    async prepareMyResults() {
      if (this.user && !this.myResults) {
        const response = (await profileService.getResults(/*context:*/'microsoft-teams')).data;
        this.myResults = response.data;
        console.log('my results', this.myResults);
      }
    },

    async linkEvent(eventId) {
      this.selectedEventId = eventId;
      await this.linkWithTeams();
    },
    async createOrLinkEvent() {
      if (this.selectedResult) {
        this.currentStep ++;
        this.finalize();
      }
      else if (this.selectedEventId) {
        await this.linkWithTeams();
      }
      else if (this.name) {
        await this.createNewEvent();
      }
    },

    async linkWithTeams() {
      var jsonString = JSON.stringify(this.teamsWrapper.getTeamsInfo());
      console.log('link with teams', this.selectedEventId, jsonString);
      var response = await eventManagerService.linkWithTeams(this.selectedEventId, jsonString);
      this.$helpers.toastResponse(this, response.data, 'Event is linked with Microsoft Teams.');
      if (response.data.status === 'OK') {
        this.event = (await eventManagerService.get(this.selectedEventId)).data;
        this.currentStep ++;
        this.finalize();
      }
    },

    async createNewEvent() {
        try {
          var response = await eventManagerService.create({
            name: this.name,
            timezone: this.timezone,
            teams_info: this.teamsWrapper.getTeamsInfo(),
          });
          this.$analytics.event('create_event');
          this.event = response.data;
          this.event.from = this.activeFrom;
          this.event.till = this.activeTill;
          this.saveEvent();
          this.currentStep ++;
        }
        catch (ex) {
          this.error = ex.response.data.msg;
          //console.log('ERR', ex, ex.response);
        }
    },

    async saveEvent() {
      console.log('saveEvent', this.event);
      this.event = (await eventManagerService.put(this.event)).data;
    },

    async finalize() {
      if (this.teamsWrapper && this.teamsWrapper.microsoftTeams) {
        var microsoftTeams = this.teamsWrapper.microsoftTeams;
        var config = microsoftTeams.pages.config;
        this.currentStep ++;
        console.log('teams available in tab, enabling save now', microsoftTeams);
        //await this.tryVerifyPermissions();
        config.setValidityState(true);
        config.registerOnSaveHandler((saveEvent) => {
          console.log('teams available in tab, registerOnSaveHandler now', microsoftTeams);
          if (this.selectedResult) {
            config.setConfig({
              websiteUrl: window.location.origin + `/events/${this.selectedResult.event.id}/${this.selectedResult.race.id}/results/${this.selectedResult.result.id}`,
              contentUrl: window.location.origin + "/apps/teams-v2/result",
              entityId: this.teamsWrapper.resultToEntityId(this.selectedResult.event, this.selectedResult.race, this.selectedResult.result),
              suggestedDisplayName: "CorporateFitness.app"
            });
          }
          else if (this.event) {
            config.setConfig({
              websiteUrl: window.location.origin + "/events/" + this.event.id,
              contentUrl: window.location.origin + "/apps/teams-v2/content",
              entityId: this.event.id,
              suggestedDisplayName: "CorporateFitness.app"
            });
          }
          console.log('teams available in tab, notifySuccess now');
          saveEvent.notifySuccess();
        });
      }
    },
  },
  computed: {
    // teamsWrapper() {
    //   return new MicrosoftTeamsWrapper(this, this.microsoftTeams, this.teamsContext);
    // },
    ...mapGetters({
      user: "user"
    }), 
  },
  watch: {
    // teamsContext() {
    //   //this.tryPrepare();
    // },

    // microsoftTeams() {
    //   //this.tryPrepare();
    // }
  }
};
</script>
<style lang="scss">
  .teams-app--container {
    background-color: #fff !important;
  }

  .v-stepper--vertical .v-stepper__items { padding: 1em !important;}
  .v-stepper--vertical .v-stepper__content { padding: 0 !important;}
  .v-application--is-ltr .theme--light.v-stepper--vertical .v-stepper__content:not(:last-child) { border-left-style: none !important; }
  .v-application--is-ltr .v-stepper--vertical .v-stepper__content { margin: 0 !important;}

</style>

